<app-repetir-tutorial (repetirTutorial)="onRepetirTutorial()"></app-repetir-tutorial>

<!-- notificacao -->
<app-novidades-notificacao (novidadesExibidas)="startTutorial($event)"></app-novidades-notificacao>

<!-- FILTROS DASHBOARD -->
<app-filtros-dashboard
  [dadosFiltroGraficoDashboard]="dadosFiltroGraficoDashboard"
  [loading]="recebeLoadButton"
  [isFiltrosIndicadorFadiga]="tabFadigaSelected"
  (filtrar)="filtrarGraficos($event)"
>
</app-filtros-dashboard>

<p-dialog
  [header]="'analiseProntidao.GraficoEvolucaoTemporal' | translate"
  id="idDialogAcaoPendente"
  [modal]="true"
  [style]="{ width: '765px' }"
  [showHeader]="true"
  [(visible)]="showDialogAcaoPendente"
  blockScroll="true"
>
  @if(exibirGraficoAnaliseProntidao) {
  <div style="width: 100%; height: 500px">
    <app-detalhe-acao-a-definir
      [ApenasGrafico]="exibirGraficoAnaliseProntidao"
      [detalheAcaoADefinir]="detalheAcaoADefinir"
    ></app-detalhe-acao-a-definir>
  </div>
  }
</p-dialog>

<div class="container" [ngClass]="{ 'mt-0': readOnly }">
  <!--CONTAINER DOS GRAFICOS-->
  <div class="colum-graficos">
    @if(exibirInformacoesTop()) {
    <div class="p-grid">
      <div class="p-col-9">
        @if(ExibirInformacaoUnica) {
        <div class="linha">
          <div class="container-item-linha">
            <span class="label" translate>dashboard.nomeCompleto</span>
            <span class="valor">{{ detalheAcaoADefinirUnico.dsUsuario }}</span>
          </div>
          <div class="container-item-linha">
            <span class="label" translate>dashboard.matricula</span>
            <span class="valor">{{ detalheAcaoADefinirUnico.cdMatricula }}</span>
          </div>
        </div>
        } @if(ExibirInformacaoUnica || ExibirHierarquia) {
        <div class="linha" style="margin-bottom: 20px">
          @if(ExibirInformacaoUnica || !ExibirHierarquia) {
          <div class="container-item-linha">
            <span class="label" translate>dashboard.cargo</span>
            <span class="valor">{{ detalheAcaoADefinirUnico.dsCargo }}</span>
          </div>
          } @if(ExibirHierarquia) {
          <div class="container-item-linha">
            <span class="label" translate>dashboard.hierarquia</span>
            <span class="valor">
              {{ detalheAcaoADefinirUnico.dsHierarquia }}
            </span>
          </div>
          }
        </div>
        }
      </div>
      <!--BOTOES DE FILTRO E DOWLOAND DE ARQUIVO-->
      <div class="p-col-1" style="margin-bottom: 10px">
        @if(botaoGraficoAcaoPendente && showBotaoFiltro) {
        <button
          class="botao-filtrar"
          [pTooltip]="'analiseProntidao.GraficoEvolucaoTemporal' | translate"
          (click)="exibirDialogAcaoPendente()"
          tooltipPosition="top"
        >
          <em class="material-icons" style="margin-top: 7px">trending_up</em>
        </button>
        }
      </div>
    </div>
    }

    <div data-tutorial="dashboard-container" class="container-graficos">
      @if(showBotaoFiltro) {
      <em (click)="exibirDialogoFiltroGrafico()" class="material-icons botao-filtrar-aba"> filter_list </em>
      }
      <em (click)="imprimirPagina()" class="material-icons botao-filtrar-aba" style="right: 16px">print</em>

      <div style="width: 100%">
        <p-tabView id="dashboard-tab" class="tabView" (onChange)="handleChange()" [(activeIndex)]="activeTab">
          <p-tabPanel
            class="tabPanel_jornada"
            #tabProntidao
            [tooltip]="'dashboard.hintAbaAvaliacoes' | translate"
            [header]="'dashboard.abaAvaliacoes' | translate"
          >
            <!--TOTALIZADORES-->
            @if(!loaderResumo) {
            <div class="container-totalizadores">
              <div class="p-p-1" data-tutorial="usuario-atividade">
                <app-resumo-card
                  value="{{ resumo.qtTotal | number }} / {{ resumo.qtTotalGeral | number }}"
                  [title]="'dashboard.resumoTotal' | translate"
                  [disabled]="!habilitarConsulta || readOnly"
                  (click)="!readOnly && habilitarConsulta && (exibirListaUsuariosCadastrados = true)"
                >
                  <ng-template #badgeContent>
                    <app-resumo-badge-content [resumo]="resumo"></app-resumo-badge-content>
                  </ng-template>
                </app-resumo-card>
              </div>
              <div class="p-p-1" data-tutorial="avaliacoes-realizadas">
                <app-resumo-card
                  [value]="resumo.qtRealizado | number"
                  [title]="'dashboard.resumoRealizado' | translate"
                  [disabled]="!habilitarConsulta"
                  (click)="habilitarConsulta && (exibirListaAvaliacoesRealizadas = true)"
                >
                </app-resumo-card>
              </div>
              <div class="p-p-1" data-tutorial="usuarios-sem-avaliacoes">
                <app-resumo-card
                  [value]="resumo.qtPendente | number"
                  [title]="'dashboard.resumoPendente' | translate"
                  [disabled]="!habilitarConsulta"
                  (click)="habilitarConsulta && (exibirListaUsuariosPendentes = true)"
                >
                </app-resumo-card>
              </div>
            </div>
            } @else {
            <ng-container *ngTemplateOutlet="tabLoader"></ng-container>
            }
            <div class="cards-graficos p-grid">
              @for(item of listaGraficosExibir; track item; let i = $index, last = $last) {
              @if(!item.hasError) { @if(!item.isLoading) {
              <div [id]="i" class="p-col-12 p-md-6" [attr.data-tutorial]="'grafico-' + item.dados.dsChave">
                <div class="container-grafico card">
                  <app-card-chart-container
                    [isFilter]="item.dados.isFilter"
                    [type]="item.dados.tpGrafico.toUpperCase()"
                    [opcoesFiltro]="item.dados.opcoesFiltro"
                    [data]="item.dados"
                    (valorId)="recebeIdPergunta($event, item.dados)"
                  />
                </div>
                @if(!last && i > 0 && (i == 1 || i % 4 === 0)) {
                <div class="print-break-page"></div>
                }
              </div>
              }@else {
              <ng-container *ngTemplateOutlet="graficosLoader"></ng-container>
              } } }
            </div>
          </p-tabPanel>
          @if(flIntraJornada) {
          <p-tabPanel
            #tabIntrajornada
            class="tabPanel_intrajornada"
            [tooltip]="'dashboard.hintAbaFadiga' | translate"
          >
            <ng-template pTemplate="header">
              <span data-tutorial="aba-intrajornada" translate> dashboard.abaFadiga </span>
            </ng-template>
            <!--TOTALIZADORES-->
            @if(!loaderResumo) {
            <div class="container-totalizadores">
              <!-- usuários cadastrados -->
              <div class="p-p-1">
                <app-resumo-card
                  value="{{ resumo.qtTotal | number }} / {{ resumo.qtTotalGeral | number }}"
                  [title]="'dashboard.resumoTotal' | translate"
                  [disabled]="!habilitarConsulta || readOnly"
                  (click)="!readOnly && habilitarConsulta && (exibirListaUsuariosCadastrados = true)"
                >
                  <ng-template #badgeContent>
                    <app-resumo-badge-content [resumo]="resumo"></app-resumo-badge-content>
                  </ng-template>
                </app-resumo-card>
              </div>
              <div class="p-p-1">
                <app-resumo-card
                  [value]="resumo.qtRealizado | number"
                  [title]="'dashboard.resumoRealizado' | translate"
                  [disabled]="!habilitarConsulta"
                  (click)="habilitarConsulta && (exibirListaAvaliacoesRealizadas = true)"
                >
                </app-resumo-card>
              </div>
              <div class="p-p-1">
                <app-resumo-card
                  [value]="resumo.qtPendente | number"
                  [title]="'dashboard.resumoPendente' | translate"
                  [disabled]="!habilitarConsulta || readOnly"
                  (click)="habilitarConsulta && (exibirListaUsuariosPendentes = true)"
                >
                </app-resumo-card>
              </div>
            </div>
            } @else {
            <ng-container *ngTemplateOutlet="tabLoader"></ng-container>
            }
            <div class="cards-graficos p-grid">
              @for(item of listaGraficosExibir; track item; let i = $index, last = $last) {
              @if(!item.hasError) { @if(!item.isLoading) {
              <div [id]="i" class="p-col-12 p-md-6">
                <div class="container-grafico card">
                  <app-card-chart-container
                    [isFilter]="item.dados.isFilter"
                    [type]="item.dados.tpGrafico.toUpperCase()"
                    [opcoesFiltro]="item.dados.opcoesFiltro"
                    [data]="item.dados"
                    (valorId)="recebeIdPergunta($event, item.dados)"
                  />
                </div>
                @if(!last && i > 0 && (i == 1 || i % 4 === 0)) {
                <div class="print-break-page"></div>
                }
              </div>
              }@else {
              <ng-container *ngTemplateOutlet="graficosLoader"></ng-container>
              } } }
            </div>
          </p-tabPanel>
          } @if(exibirAbaSegurancaPsicologica) {
          <p-tabPanel class="tabPanel_jornada" #tabSeguranca>
            <ng-template pTemplate="header">
              <span data-tutorial="aba-seguranca" class="aba-seguranca">
                <span translate> dashboard.abaSeguranca </span>
              </span>
            </ng-template>
            @if(!recebeLoadButton) {
            <app-seguranca-psicologica
              [dados]="responseSegurancaPsicologica"
              [filtros]="dadosFiltroGraficoDashboard"
            ></app-seguranca-psicologica>
            } @else {
            <ng-container *ngTemplateOutlet="segurancaPsicologicaLoader"></ng-container>
            }
          </p-tabPanel>
          } @if(exibirAbaSinalizadorProntidao) {
          <p-tabPanel class="tabPanel_jornada" #tabSinalizadorProntidao>
            <ng-template pTemplate="header">
              <span data-tutorial="aba-sinalizador-prevencao" class="aba-seguranca">
                <span translate> sinalizador.tituloAba </span>
                <em class="fas fa-exclamation-circle info-badge"> </em>
              </span>
            </ng-template>
            @if(tabSinalizadorProntidao?.selected) {
            <app-sinalizador-prontidao #sinalizadorProntidao />
            }
          </p-tabPanel>
          } @if(exibirAbaFadiga) {
          <p-tabPanel class="tabPanel_jornada" #tabFadiga>
            <ng-template pTemplate="header">
              <span data-tutorial="aba-indicador-fadiga" translate> dashboard.abaIndicadorFadiga </span>
            </ng-template>
            @if(!recebeLoadButton) {
            <app-indicadores-fadiga
              #indicadorFadiga
              [dados]="responseFadiga"
              [filtros]="dadosFiltroGraficoDashboard"
            />
            } @else {
            <ng-container *ngTemplateOutlet="fadigaLoader"></ng-container>
            }
          </p-tabPanel>
          }
        </p-tabView>
      </div>
    </div>
  </div>
</div>

<p-toast [style]="{ marginTop: '80px' }" position="top-right" key="tc"></p-toast>

<p-dialog
  id="listaUsuariosCadastrados"
  modal="true"
  [style]="{ width: '90%', 'max-width': '1100px' }"
  [blockScroll]="true"
  [header]="'dashboard.resumoTotal' | translate"
  [(visible)]="exibirListaUsuariosCadastrados"
  [draggable]="false"
>
  <div class="p-grid">
    @if(exibirListaUsuariosCadastrados) {
    <div id="divTblUsuariosCadastrados" class="p-col-12">
      <prontos-table
        [loading]="loading"
        [showHeader]="true"
        [selectWithRadioButton]="false"
        [lazy]="true"
        [values]="listaUsuariosCadastrados"
        [columns]="colunasUsuariosCadastrados"
        [rows]="30"
        [first]="1"
        [selectWithCheckbox]="false"
        (pageChange)="atualizarSourceObservableUsuariosCadastrados($event)"
        [sourceObservable]="sourceObservableUsuariosCadastrados"
        [page]="nrPaginaCadastrados"
      />
    </div>
    }
  </div>
</p-dialog>

<p-dialog
  id="listaAvaliacoesRealizadas"
  modal="true"
  [style]="{ width: '90%', 'max-width': '1100px' }"
  [blockScroll]="false"
  [header]="'dashboard.resumoRealizado' | translate"
  [(visible)]="exibirListaAvaliacoesRealizadas"
  [draggable]="false"
>
  <div class="p-grid">
    @if(exibirListaAvaliacoesRealizadas) {
    <div id="divTblAvaliacoesRealizadas" class="p-col-12">
      <prontos-table
        [loading]="loading"
        [showHeader]="true"
        [selectWithRadioButton]="false"
        [lazy]="true"
        [values]="listaAvaliacoesRealizadas"
        [columns]="colunasAvaliacoesRealizadas"
        [rows]="30"
        [first]="1"
        [selectWithCheckbox]="false"
        (pageChange)="atualizarSourceObservableAvaliacoesRealizadas($event)"
        [sourceObservable]="sourceObservableAvaliacoesRealizadas"
        [page]="nrPaginaRealizadas"
      />
    </div>
    }
  </div>
</p-dialog>

<p-dialog
  id="listaUsuariosPendentes"
  modal="true"
  [style]="{ width: '90%', 'max-width': '1100px' }"
  [blockScroll]="true"
  [header]="'dashboard.resumoPendente' | translate"
  [(visible)]="exibirListaUsuariosPendentes"
  [draggable]="false"
>
  <div class="p-grid">
    @if(exibirListaUsuariosPendentes) {
    <div id="divTblUsuariosPendentes" class="p-col-12">
      <prontos-table
        [loading]="loading"
        [showHeader]="true"
        [selectWithRadioButton]="false"
        [lazy]="true"
        [values]="listaAvaliacoesPendentes"
        [columns]="colunasUsuariosPendentes"
        [rows]="30"
        [first]="1"
        [selectWithCheckbox]="false"
        (pageChange)="atualizarSourceObservable($event)"
        [sourceObservable]="sourceObservable"
        [page]="nrPaginaPendentes"
      />
    </div>
    }
  </div>
</p-dialog>

@if(exibirResumoPopup) {
<app-grafico-resumo-popup
  [titulo]="tituloResumo"
  [(isPopupVisible)]="exibirResumoPopup"
  totalRegistros="listaResumoGraficoEstatiscaAcoes?.qtTotalRegistros"
  [dados]="listaPopupResumo?.dados"
  [showColunaDtUltimaExecucao]="showColumaDataPopupResumo"
  [labelDtUltimaExecucao]="labelDataUltimoTeste"
  [loading]="loading"
/>
}

<ng-template #tabLoader>
  <div class="container-resumo-loader">
    @for(i of [1,2,3]; track i) {
    <div>
      <p-skeleton borderRadius="17px" height="66px"></p-skeleton>
    </div>
    }
  </div>
</ng-template>

<ng-template #graficosLoader>
  <div class="p-col-12 p-md-6">
    <p-skeleton borderRadius="17px" height="320px" width="100%"></p-skeleton>
  </div>
</ng-template>

<ng-template #segurancaPsicologicaLoader>
  <div class="p-grid">
    @for(i of [1,2]; track i) {
    <div class="p-col-12 p-md-6">
      <p-skeleton borderRadius="17px" height="65px"></p-skeleton>
    </div>
    }
    <div class="p-col-12">
      <p-skeleton borderRadius="17px" height="470px"></p-skeleton>
    </div>
  </div>
</ng-template>

<ng-template #fadigaLoader>
  <div class="p-grid">
    @for(i of [1,2,3]; track i) {
    <div class="p-col-12 p-md-4">
      <p-skeleton borderRadius="17px" height="295px"></p-skeleton>
    </div>
    }
  </div>
</ng-template>
